export function onlyNums(value: string): string {
  return value.replace(/[^\d]/g, '') || '';
}

export function trim(value: string): string {
  return value.trim();
}

export const onlyTwoDecimals = (value: string): number =>
  parseFloat(value.replace(/^(\d+(\.\d{1,2})?).*$/, '$1'));
