import { isValidPhoneNumber } from 'react-phone-number-input';

export const isValidIdnp = (v: string | undefined): string | undefined => {
  if (v && v.length === 13) {
    const n1 = Number(v[0]) + Number(v[3]) + Number(v[6]) + Number(v[9]);
    const n2 = Number(v[1]) + Number(v[4]) + Number(v[7]) + Number(v[10]);
    const n3 = Number(v[2]) + Number(v[5]) + Number(v[8]) + Number(v[11]);
    const n = Number(v[12]);

    return (n1 * 7 + n2 * 3 + n3) % 10 === n
      ? undefined
      : 'validation.incorrect_idnp';
  }

  return 'validation.incorrect_idnp';
};

export const isValidPhone = (value: string): boolean | undefined => {
  if (!value && value.length > 3) return false;

  const phone = `+${value}`;

  return isValidPhoneNumber(phone);
};

export const required = (value: string | undefined): undefined | string =>
  value ? undefined : 'ra.validation.required';

export const requiredValue = (
  value: string | undefined,
  error: string,
): undefined | string => (value ? undefined : error);

export const minValue =
  (min: number): any =>
  (value: number): string | undefined =>
    value.toString().length >= min
      ? undefined
      : `Неверная длина поля. Вы ввели ${
          value.toString().length
        }/${min} символов`;

export const validatePurchaseAmount = (
  purchaseAmount: number,
  creditAmount: number,
): string | undefined =>
  purchaseAmount >= creditAmount
    ? undefined
    : `Сумма покупки должна быть не менее выбранной суммы кредита`;
