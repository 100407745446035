import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormControl, Grid } from '@material-ui/core';
import {
  useDataProvider,
  Loading,
  useNotify,
  HttpError,
  Notification,
  DataProvider,
  useRedirect,
  composeValidators,
} from 'react-admin';
import { Alert } from '@material-ui/lab';
import { withTypes } from 'react-final-form';

import LoanRequestPreparer from '../../../../utils/prepare/loanRequestPreparer';

import {
  HttpErrorItem,
  ICurrentStepProps,
  IGiveOutMethod,
  IPartner,
} from '../../../../types';

import { SelectField } from '../../../SelectField';
import { FormField } from '../../../FormField';
import { SubmitButton } from '../../../SubmitButton';

import {
  required,
  validatePurchaseAmount,
} from '../../../../utils/validation/validation';
import { onlyTwoDecimals, trim } from '../../../../utils/parsers';

type FormValues = {
  giveoutMethod: string;
  account: string;
  commodity_category: string;
  commodity_name: string;
  commodity_amount: number;
};

const { Form } = withTypes<FormValues>();

const GIVE_OUT_METHODS: IGiveOutMethod[] = [
  { value: 1, title: 'Iban', isRequiresAccount: true },
  { value: 2, title: 'Наличные', isRequiresAccount: false },
  { value: 3, title: 'E-Wallet RunPay', isRequiresAccount: true },
  { value: 4, title: 'E-Wallet BPay', isRequiresAccount: true },
  { value: 5, title: 'E-Wallet Paynet', isRequiresAccount: true },
  { value: 7, title: 'Partner: Выдача наличных', isRequiresAccount: false },
  {
    value: 8,
    title: 'Partner: Покупка товаров или услуг',
    isRequiresAccount: false,
  },
];

const GiveOutMethod: FC<ICurrentStepProps> = ({ data }) => {
  const dataProvider: DataProvider = useDataProvider();
  const notify = useNotify();
  const redirect = useRedirect();

  const [loading, setLoading] = useState(true);
  const [partner, setPartner] = useState<IPartner | null>(null);

  useEffect(() => {
    dataProvider
      .request('/third-party/me')
      .then(({ data: response }: any) => {
        setPartner(response.data.attributes.partner);
      })
      .catch(() => {
        notify('Ошибка получения доступных методов выдачи', 'warning');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [dataProvider, notify]);

  const giveOutMethods = useMemo(() => {
    return GIVE_OUT_METHODS.filter((item) =>
      partner?.giveout_methods.includes(item.value),
    );
  }, [partner]);

  const comodityCategoriesOptions = useMemo(() => {
    return (
      partner?.commodity_categories.map((item) => {
        return {
          value: item.id,
          title: item.name,
        };
      }) || []
    );
  }, [partner?.commodity_categories]);

  const onSubmit = (formData: FormValues): void => {
    setLoading(true);

    dataProvider
      .sendPost(
        '/wallet/partner/wallet-loan-requests',
        LoanRequestPreparer({ ...data, ...formData }),
      )
      .then((res: any) => {
        const applicationId = res.data.ApplicationId;
        const isNewUSer = data.user.id === null;
        const needUploadPassport = data.user.files_count < 2;
        const isSmsVerification = Boolean(partner?.settings.sms_verification);

        localStorage.setItem('isHasAccessPhotoUpload', 'true');
        localStorage.setItem(
          'isNeedSmsVerification',
          isSmsVerification.toString(),
        );

        if (isNewUSer || needUploadPassport) {
          redirect(`/loan-request/${applicationId}/upload-passport`);
        } else if (isSmsVerification) {
          redirect(`/loan-request/${applicationId}/confirmation`);
        } else {
          dataProvider
            .sendPost(
              `/wallet/partner/wallet-loan-requests/${applicationId}/confirm-request`,
              { code: null },
            )
            .then(() => {
              setTimeout(() => {
                redirect(`/loan-request/${applicationId}/show`);
              }, 2000);
            });
        }

        // ? `/loan-request/${applicationId}/upload-passport`
        // //: isSmsVerification ? `/loan-request/${applicationId}/confirmation`
        // : `/loan-request/${applicationId}/show`;
      })
      .catch((error: HttpError) => {
        if (error.status === 422) {
          error.body.Errors.forEach((item: HttpErrorItem) => {
            notify(`${item.PropertyPath}: ${item.Message}`, 'warning');
          });
        } else {
          notify(`Неизвестная ошибка: ${error.status}`, 'warning');
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const isShowAccount = (id: string): boolean | undefined => {
    return GIVE_OUT_METHODS.find((item: IGiveOutMethod) => item.value === +id)
      ?.isRequiresAccount;
  };

  if (loading) {
    return <Loading />;
  }

  if (partner?.giveout_methods.length === 0) {
    return <Alert severity="warning">Нет доступных методов выдачи</Alert>;
  }

  return (
    <Grid container>
      <Grid item>
        <Form
          onSubmit={onSubmit}
          render={({ handleSubmit, values }) => (
            <form onSubmit={handleSubmit} noValidate>
              <FormControl fullWidth margin="normal">
                <SelectField
                  defaultValue=""
                  options={giveOutMethods}
                  label="loan_request.give_out_method"
                  disabled={loading}
                  validate={required}
                  name="giveoutMethod"
                />
              </FormControl>

              {isShowAccount(values.giveoutMethod) && (
                <FormControl fullWidth>
                  <FormField
                    name="account"
                    label="loan_request.account_number"
                    disabled={loading}
                    validate={required}
                    type="text"
                    parse={trim}
                  />
                </FormControl>
              )}

              {+values.giveoutMethod === 8 && (
                <>
                  <FormControl fullWidth margin="normal">
                    <SelectField
                      defaultValue=""
                      options={comodityCategoriesOptions}
                      label="loan_request.commodity_category"
                      disabled={loading}
                      validate={required}
                      name="commodity_category"
                    />
                  </FormControl>

                  <FormControl fullWidth>
                    <FormField
                      name="commodity_name"
                      label="loan_request.commodity_name"
                      disabled={loading}
                      validate={required}
                      type="text"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <FormField
                      name="commodity_amount"
                      label="loan_request.commodity_amount"
                      disabled={loading}
                      validate={composeValidators(required, (value: number) =>
                        validatePurchaseAmount(value, data.loan.amount),
                      )}
                      parse={onlyTwoDecimals}
                      type="number"
                    />
                  </FormControl>
                </>
              )}

              <FormControl fullWidth margin="normal">
                <SubmitButton
                  loading={loading}
                  title="ra.action.send_loan_request"
                />
              </FormControl>
              <Notification />
            </form>
          )}
        />
      </Grid>
    </Grid>
  );
};

export default GiveOutMethod;
